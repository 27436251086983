export const suggestedTagsData = [
  {
    id: 'artist',
    label: 'Artist',
    tags: [
      '#sketch',
      '#painting',
      '#draw',
      '#instaart',
      '#creative',
      '#arte',
      '#ink',
      '#streetart',
      '#color',
      '#sketchbook',
      '#artoftheday',
      '#paint',
      '#doodle',
      '#contemporaryart',
      '#pencil',
      '#artsy',
      '#graffiti',
      '#digitalart',
      '#abstract',
      '#instaartist',
      '#watercolor',
      '#picture',
      '#artistic',
      '#arts',
      '#gallery',
      '#fineart',
      '#artgallery',
      '#tattoos',
      '#graphic',
      '#illustrator',
      '#sketch',
      '#masterpiece',
      '#pen',
      '#instadraw',
      '#paper',
      '#arts_help',
      '#myart',
      '#modernart',
      '#sketching',
      '#drawings',
      '#doodles',
      '#doodling',
      '#doodlesofinstagram',
      '#doodleart',
      '#markers',
      '#paintings',
      '#artshow',
      '#myartwork',
      '#newartwork',
      '#artists',
      '#instaartoftheday',
      '#instaartwork',
      '#sketchaday',
      '#colour',
      '#instaartistic',
      '#instaartsy',
      '#instaarts',
      '#artinfo',
      '#instaarthub',
      '#instaarte',
      '#mydrawing',
      '#instaartpop',
    ],
  },
  {
    id: 'books',
    label: 'Books',
    tags: [
      '#books',
      '#bookstagram',
      '#bookworm',
      '#book',
      '#reading',
      '#booklover',
      '#read',
      '#bibliophile',
      '#booknerd',
      '#bookish',
      '#instabook',
      '#bookaddict',
      '#igreads',
      '#bookaholic',
      '#bookphotography',
      '#bookstagramfeature',
      '#instabooks',
      '#reader',
      '#booklove',
      '#bookporn',
      '#literature',
      '#library',
      '#booknerdigans',
      '#bookstagrammer',
      '#bookshelf',
      '#author',
      '#books',
      '#bookblogger',
      '#yalit',
      '#igbooks',
      '#booksofinstagram',
      '#becauseofreading',
      '#bookgeek',
      '#ilovebooks',
      '#bookishfeatures',
      '#currentlyreading',
      '#currentread',
      '#vscobooks',
      '#nowreading',
      '#goodreads',
      '#booklion',
      '#instaread',
      '#amreading',
      '#instareads',
    ],
  },
  {
    id: 'cleanEating',
    label: 'Clean Eating',
    tags: [
      '#cleaneating',
      '#healthychoices',
      '#healthyeating',
      '#healthyfood',
      '#weightloss',
      '#cleaneats',
      '#fitfood',
      '#instahealth',
      '#nutrition',
      '#plantbased',
      '#glutenfree',
      '#getfit',
      '#paleo',
      '#healthyliving',
      '#determination',
      '#healthylifestyle',
      '#active',
      '#bbg',
      '#exercise',
      '#fitnessaddict',
      '#cardio',
      '#veganfoodshare',
      '#whatveganseat',
      '#strong',
      '#cleanfood',
      '#vegetarian',
      '#protein',
      '#weightlossjourney',
      '#organic',
      '#cleaneating',
      '#slimmingworld',
      '#eathealthy',
      '#healthylife',
      '#sw',
      '#weightwatchers',
      '#veganfood',
      '#vegansofig',
      '#fooddiary',
      '#slimmingworldjourney',
      '#realfood',
      '#wholefoods',
      '#healthyeats',
      '#eatforabs',
      '#foodisfuel',
      '#wholefood',
      '#dairyfree',
      '#whole30',
      '#healthyfoodshare',
      '#eattherainbow',
      '#hclf',
      '#fitfoodie',
      '#nourish',
      '#healthyfoodporn',
      '#jerf',
      '#primal',
      '#eatrealfood',
      '#grainfree',
      '#lchf',
      '#livinghealthy',
      '#paleodiet',
      '#cleaneatinglifestyle',
      '#cleaneatingforlife',
      '#cleaneatingrecipe',
      '#cleaneatingjourney',
      '#gettinghealthy',
      '#cleaneatingaddict',
      '#cleaneatingideas',
      '#healthfood',
      '#cleaneatingdiet',
      '#paleolifestyle',
      '#comidadeverdade',
      '#cleaneatingchallenge',
    ],
  },
  {
    id: 'cooking',
    label: 'Cooking',
    tags: [
      '#cooking',
      '#cook',
      '#chef',
      '#homemade',
      '#recipe',
      '#foodpic',
      '#dinner',
      '#kitchen',
      '#homecooking',
      '#cheflife',
      '#eat',
      '#foodart',
      '#yum',
      '#baking',
      '#cuisine',
      '#chefsofinstagram',
      '#lunch',
      '#foodpics',
      '#foodlover',
      '#recipes',
      '#foodstyling',
      '#foodgasm',
      '#tasty',
      '#recipeoftheday',
      '#recetas',
      '#ricette',
      '#foodvideos',
      '#cooking',
      '#foodvideo',
      '#healthyrecipes',
      '#foodphotography',
      '#foodblogger',
      '#foods',
      '#foodies',
      '#foodshare',
      '#foodgram',
      '#foodoftheday',
      '#hungry',
      '#foodblog',
      '#fooddiary',
      '#foodphoto',
      '#foodlove',
      '#eating',
      '#foodforfoodies',
      '#foodlovers',
      '#nomnom',
      '#foodforthought',
      '#foodaddict',
      '#igfood',
      '#f52grams',
      '#feedfeed',
      '#huffposttaste',
      '#buzzfeast',
      '#eeeeeats',
      '#beautifulcuisines',
      '#thekitchn',
      '#foodandwine',
      '#forkyeah',
      '#yahoofood',
      '#onthetable',
      '#buzzfeedfood',
      '#vscofood',
      '#lifeandthyme',
      '#eater',
      '#heresmyfood',
      '#food52',
      '#gloobyfood',
      '#foodgawker',
      '#eattheworld',
      '#eatfamous',
      '#tastingtable',
      '#dailyfoodfeed',
      '#spoonfeed',
      '#foodbeast',
      '#hautecuisines',
      '#foodblogfeed',
      '#onmytable',
    ],
  },
  {
    id: 'craftBeer',
    label: 'Craft Beer',
    tags: [
      '#beer',
      '#craftbeer',
      '#beerporn',
      '#instabeer',
      '#beerstagram',
      '#beergeek',
      '#bier',
      '#beertography',
      '#beernerd',
      '#birra',
      '#beerlover',
      '#ipa',
      '#craftbeerporn',
      '#cheers',
      '#beers',
      '#beerme',
      '#beersnob',
      '#brewery',
      '#beertime',
      '#drinklocal',
      '#beergasm',
      '#craftbeerlife',
      '#craftbeernotcrapbeer',
      '#biere',
      '#bar',
      '#craftbrew',
      '#drink',
      '#craftbeerlover',
      '#beeradvocate',
      '#beersofinstagram',
      '#craftnotcrap',
      '#drinkcraft',
      '#craftbeerjunkie',
      '#craftbeergeek',
      '#ratebeer',
      '#ilovebeer',
      '#beerpics',
      '#thebeernation',
      '#alcohol',
      '#drinks',
      '#booze',
      '#drinking',
      '#drinkporn',
      '#instadrink',
      '#happyhour',
      '#beer',
      '#drinkup',
    ],
  },
  {
    id: 'fashion',
    label: 'Fashion',
    tags: [
      '#stylish',
      '#outfitoftheday',
      '#shoes',
      '#lookbook',
      '#instastyle',
      '#menswear',
      '#fashiongram',
      '#fashionable',
      '#fashionblog',
      '#look',
      '#streetwear',
      '#fashiondiaries',
      '#lookoftheday',
      '#fashionstyle',
      '#streetfashion',
      '#jewelry',
      '#clothes',
      '#fashionpost',
      '#styleblogger',
      '#menstyle',
      '#trend',
      '#accessories',
      '#fashionaddict',
      '#wiw',
      '#wiwt',
      '#designer',
      '#trendy',
      '#blog',
      '#hairstyle',
      '#whatiwore',
      '#styles',
      '#mensstyle',
      '#men',
      '#stylist',
      '#chic',
      '#currentlywearing',
      '#whatiworetoday',
      '#ootdshare',
      '#todayimwearing',
      '#outfitpost',
      '#mylook',
      '#todaysoutfit',
      '#fblogger',
      '#aboutalook',
      '#ootdmagazine',
      '#styleblog',
      '#liketkit',
      '#mystyle',
      '#stylediaries',
      '#lotd',
      '#ootn',
      '#igstyle',
      '#realoutfitgram',
      '#heels',
      '#purse',
      '#glam',
      '#dapper',
      '#swagger',
      '#shirt',
      '#styleoftheday',
      '#classy',
      '#igfashion',
      '#fashionkiller',
      '#ootdmag',
      '#introfashion',
      '#dailystreetlooks',
      '#commonprojects',
      '#hypemask',
      '#outfitfromabove',
      '#urbanfashion',
      '#bestofstreetwear',
      '#outfitsociety',
    ],
  },
  {
    id: 'fitness',
    label: 'Fitness',
    tags: [
      '#abs',
      '#muscle',
      '#instafit',
      '#gymlife',
      '#fitnessmodel',
      '#fitnessmotivation',
      '#fitnessaddict',
      '#cardio',
      '#fitgirl',
      '#exercise',
      '#weightloss',
      '#shredded',
      '#strong',
      '#crossfit',
      '#gains',
      '#fitlife',
      '#girlswholift',
      '#nutrition',
      '#aesthetics',
      '#body',
      '#fitspiration',
      '#physique',
      '#getfit',
      '#dedication',
      '#cleaneating',
      '#muscles',
      '#yoga',
      '#nopainnogain',
      '#strength',
      '#bodybuilder',
      '#healthyfood',
      '#healthychoices',
      '#healthylifestyle',
      '#healthyeating',
      '#breakfast',
      '#healthyliving',
      '#healthylife',
      '#plantbased',
      '#fitfood',
      '#organic',
      '#protein',
      '#vegetarian',
      '#active',
      '#glutenfree',
      '#instahealth',
      '#veganfoodshare',
      '#determination',
      '#fitnessfreak',
      '#fitnessjourney',
      '#fitnesslifestyle',
      '#fitnesslife',
      '#gymrat',
      '#instafitness',
      '#train',
      '#fitnessgirl',
      '#getstrong',
      '#youcandoit',
      '#gymtime',
      '#justdoit',
      '#noexcuses',
      '#gainz',
      '#lift',
      '#beachbody',
    ],
  },
  {
    id: 'food',
    label: 'Food',
    tags: [
      '#foodgasm',
      '#yum',
      '#eat',
      '#foodpic',
      '#dinner',
      '#foodpics',
      '#lunch',
      '#breakfast',
      '#foodphotography',
      '#foodlover',
      '#tasty',
      '#hungry',
      '#foodblogger',
      '#dessert',
      '#healthyfood',
      '#chocolate',
      '#foods',
      '#cooking',
      '#cake',
      '#eeeeeats',
      '#foodies',
      '#eating',
      '#homemade',
      '#chef',
      '#foodblog',
      '#nomnom',
      '#delish',
      '#feedfeed',
      '#fresh',
      '#nom',
      '#icecream',
      '#foodgram',
      '#desserts',
      '#brunch',
      '#fruit',
      '#cheese',
      '#f52grams',
      '#foodstyling',
      '#fooddiary',
      '#foodshare',
      '#vscofood',
      '#foodlove',
      '#huffposttaste',
      '#foodlovers',
      '#buzzfeast',
      '#buzzfeedfood',
      '#beautifulcuisines',
      '#igfood',
      '#onthetable',
      '#foodphoto',
      '#eatfamous',
      '#forkyeah',
      '#thekitchn',
      '#foodandwine',
      '#yahoofood',
      '#lifeandthyme',
      '#eater',
      '#heresmyfood',
      '#food52',
      '#gloobyfood',
      '#foodgawker',
      '#eattheworld',
      '#tastingtable',
      '#dailyfoodfeed',
      '#spoonfeed',
      '#foodbeast',
      '#hautecuisines',
      '#foodblogfeed',
      '#onmytable',
    ],
  },
  {
    id: 'happyHour',
    label: 'Happy Hour',
    tags: [
      '#happyhour',
      '#cocktails',
      '#cheers',
      '#bartender',
      '#mixology',
      '#cocktail',
      '#drinks',
      '#mixologist',
      '#booze',
      '#craftcocktails',
      '#bartenders',
      '#bar',
      '#bartending',
      '#alcohol',
      '#drinkup',
      '#liquor',
      '#drink',
      '#cocktailtime',
      '#imbibe',
      '#drinkporn',
      '#beer',
      '#bottomsup',
      '#chopp',
      '#cocktailoftheday',
      '#cocktailbar',
      '#wine',
      '#barlife',
      '#drinking',
      '#drinkoftheday',
      '#cocktailhour',
      '#craftcocktail',
      '#gin',
      '#cocktailporn',
      '#drinkstagram',
      '#bartenderlife',
      '#rum',
      '#vodka',
      '#tipsybartender',
      '#instadrink',
      '#imbibegram',
      '#garnish',
      '#instadrinks',
    ],
  },
  {
    id: 'kids',
    label: 'Kids',
    tags: [
      '#kids',
      '#children',
      '#instakids',
      '#kidsfashion',
      '#kid',
      '#instababy',
      '#babies',
      '#child',
      '#childrenphoto',
      '#ig_kids',
      '#babygirl',
      '#kidsofinstagram',
      '#fashionkids',
      '#toddler',
      '#mom',
      '#little',
      '#babyboy',
      '#adorable',
      '#kidsstyle',
      '#play',
      '#mother',
      '#cutekidsclub',
      '#familytime',
      '#momlife',
      '#dad',
      '#instagram_kids',
      '#kids',
      '#sister',
      '#happiness',
      '#brother',
      '#familia',
      '#sisters',
      '#birthday',
      '#blessed',
      '#motherhood',
      '#childhoodunplugged',
      '#letthembelittle',
      '#candidchildhood',
      '#momswithcameras',
      '#motherhoodthroughinstagram',
      '#uniteinmotherhood',
      '#boymom',
      '#mommylife',
      '#sahm',
      '#toddlerlife',
      '#mommy',
      '#fitmom',
      '#mompreneur',
      '#momsofinstagram',
      '#momblogger',
      '#motherhoodrising',
      '#momtogs',
      '#motherhoodunplugged',
      '#dailyparenting',
      '#mommyblogger',
      '#parenting',
      '#mumlife',
      '#littleandbrave',
      '#babiesofinstagram',
      '#clickinmoms',
      '#vscomom',
    ],
  },
  {
    id: 'kitKats',
    label: 'Kit Kats',
    tags: [
      '#chocolate',
      '#dessert',
      '#desserts',
      '#dessertporn',
      '#sweets',
      '#tasty',
      '#sweettooth',
      '#foods',
      '#yum',
      '#foodgasm',
      '#eat',
      '#foodpics',
      '#eating',
      '#chocolates',
      '#hungry',
      '#candy',
      '#candies',
      '#sugar',
      '#chocolate',
      '#candybar',
      '#instasweets',
      '#sweettreats',
      '#nomnom',
      '#eeeeeats',
      '#nom',
      '#foodphotography',
      '#getinmybelly',
      '#nomnomnom',
      '#foodies',
      '#feedfeed',
      '#foodpic',
      '#foodlover',
      '#forkyeah',
      '#buzzfeast',
      '#igfood',
      '#f52grams',
      '#buzzfeedfood',
      '#huffposttaste',
      '#foodblogger',
      '#eatfamous',
      '#instayum',
      '#foodcoma',
      '#foodgram',
      '#lovefood',
      '#delish',
      '#foodbeast',
      '#kitkathaiku',
      '#candybar',
      '#japansnack',
      '#kitkat',
      '#kitkatgreentea',
      '#snackimport',
      '#flexibledieting',
      '#iifym',
      '#iifymgirls',
      '#macros',
      '#ifitfitsyourmacros',
      '#eattogrow',
      '#reversedieting',
      '#gains',
      '#flexibledieter',
      '#carbsafterdark',
      '#iifymwomen',
      '#foodisfuel',
      '#carbs',
      '#protein',
      '#girlgains',
      '#snackreview',
      '#cheatmeal',
      '#foodreview',
      '#foodreviews',
      '#foodreviewer',
      '#foodreviewblogger',
      '#foodpost',
      '#snackattack',
      '#snacktime',
      '#midnightsnack',
      '#getinmytummy',
      '#sugarrush',
      '#sugarhigh',
      '#sugaroverload',
      '#dessertfirst',
      '#junkfood',
      '#foodfinds',
      '#foodexplorer',
    ],
  },
  {
    id: 'kitties',
    label: 'Kitties',
    tags: [
      '#kittens',
      '#kitten',
      '#cats',
      '#kittensofinstagram',
      '#lovekittens',
      '#lovecats',
      '#kitty',
      '#catsagram',
      '#catlover',
      '#ilovemycat',
      '#instacat',
      '#instagramcats',
      '#catoftheday',
      '#catsofinstagram',
      '#meow',
      '#catstagram',
      '#kittycat',
      '#kitties',
      '#sleeping',
      '#catlovers',
      '#pets',
      '#petsagram',
      '#mycat',
      '#instacats',
      '#animals',
      '#kittylove',
      '#cats_of_instagram',
      '#petstagram',
      '#bestmeow',
      '#catsofig',
      '#cutecat',
      '#animal',
      '#cat_features',
      '#adorable',
      '#lovely',
      '#handsome',
      '#pink',
      '#petsofinstagram',
      '#nice',
      '#cutie',
      '#fluffy',
    ],
  },
  {
    id: 'liveMusic',
    label: 'Live Music',
    tags: [
      '#show',
      '#goodtimes',
      '#goodfriends',
      '#memories',
      '#goodtime',
      '#concert',
      '#live',
      '#singer',
      '#guitar',
      '#rock',
      '#musician',
      '#livemusic',
      '#band',
      '#festival',
      '#rap',
      '#dj',
      '#song',
      '#edm',
      '#producer',
      '#instamusic',
      '#pop',
      '#bands',
      '#musica',
      '#beats',
      '#metal',
      '#newmusic',
      '#songs',
      '#concertphotography',
      '#musicphotography',
      '#livemusicphotography',
      '#musicphotographer',
      '#concertphotographer',
      '#gigphotography',
      '#bestmusicshots',
      '#audioloveofficial',
      '#htbarp',
      '#liveconcertphotography',
      '#gig',
      '#concertphoto',
      '#livemusicphotographer',
      '#bandphotography',
      '#musicfestival',
      '#musicphoto',
      '#liveshow',
      '#rocknroll',
      '#livephotography',
      '#guitarist',
      '#concerts',
      '#stagephotography',
      '#show',
      '#stage',
      '#tour',
    ],
  },
  {
    id: 'lol',
    label: 'LOL',
    tags: [
      '#meme',
      '#memes',
      '#lmao',
      '#comedy',
      '#hilarious',
      '#humor',
      '#haha',
      '#laugh',
      '#textpost',
      '#lmfao',
      '#dankmemes',
      '#relatable',
      '#nochill',
      '#jokes',
      '#textposts',
      '#dank',
      '#savage',
      '#joke',
      '#crazy',
      '#laughing',
      '#funnyshit',
      '#funnymemes',
      '#funnypictures',
      '#hahaha',
      '#joking',
      '#wacky',
      '#silly',
      '#witty',
      '#hehe',
      '#instafun',
      '#meme',
      '#epic',
      '#instahappy',
      '#kickinit',
      '#kickit',
      '#funtime',
      '#friend',
      '#funtimes',
      '#tweegram',
      '#goodtime',
    ],
  },
  {
    id: 'makeup',
    label: 'Makeup',
    tags: [
      '#makeupaddict',
      '#lipstick',
      '#motd',
      '#makeuplover',
      '#makeupjunkie',
      '#instamakeup',
      '#wakeupandmakeup',
      '#cosmetics',
      '#lips',
      '#beautyblogger',
      '#eyeshadow',
      '#lashes',
      '#eyes',
      '#eyeliner',
      '#hairstyle',
      '#eyebrows',
      '#instabeauty',
      '#makeupoftheday',
      '#contour',
      '#makeupforever',
      '#highlight',
      '#make',
      '#makeupblogger',
      '#makeupblog',
      '#makeupaddict',
      '#makeupdolls',
      '#makeupmafia',
      '#beautyblog',
      '#bblogger',
      '#makeuplovers',
      '#beautyaddict',
      '#bbloggers',
      '#makeupbyme',
      '#makeuplove',
      '#makeupgeek',
      '#beautyguru',
      '#makeupguru',
      '#makeuplook',
      '#makeuplooks',
      '#makeupclass',
      '#makeupporn',
      '#makeuptips',
      '#makeupobsessed',
      '#ilovemakeup',
      '#highlighter',
      '#skincare',
      '#beautybloggers',
      '#beautyjunkie',
      '#instablogger',
      '#beautyproducts',
      '#maccosmetics',
      '#lifestyleblogger',
    ],
  },
  {
    id: 'movies',
    label: 'Movies',
    tags: [
      '#movies',
      '#films',
      '#cinema',
      '#movie',
      '#dvd',
      '#film',
      '#instamovies',
      '#moviestar',
      '#actor',
      '#hollywood',
      '#goodmovie',
      '#cinephile',
      '#actress',
      '#theatre',
      '#bluray',
      '#instaflick',
      '#flicks',
      '#flick',
      '#instaflicks',
      '#videos',
      '#amc',
      '#director',
      '#cinematography',
      '#star',
      '#horror',
      '#movienight',
      '#actors',
      '#comics',
      '#filmes',
      '#movietime',
      '#movieday',
      '#movies',
      '#cinephilecommunity',
      '#moviebuff',
      '#bluraycollection',
      '#bluraycollector',
      '#cultmovie',
      '#moviecollection',
      '#moviecollector',
      '#filmbuff',
      '#moviereview',
      '#dvdcollection',
      '#blurays',
    ],
  },
  {
    id: 'nyc',
    label: 'NYC',
    tags: [
      '#newyorkcity',
      '#manhattan',
      '#brooklyn',
      '#ny',
      '#nycprimeshot',
      '#what_i_saw_in_nyc',
      '#ig_nycity',
      '#newyork_instagram',
      '#icapture_nyc',
      '#instagramnyc',
      '#made_in_ny',
      '#queens',
      '#nbc4ny',
      '#topnewyorkphoto',
      '#nycdotgram',
      '#iloveny',
      '#newyorker',
      '#newyork_ig',
      '#bronx',
      '#seeyourcity',
      '#wildnewyork',
      '#loves_nyc',
      '#bigapple',
      '#igersofnyc',
      '#newyorknewyork',
      '#new',
      '#collection',
      '#building',
      '#citylife',
      '#buildings',
      '#cityscape',
      '#town',
      '#skyscraper',
      '#architecturelovers',
      '#cities',
      '#lines',
      '#archilovers',
      '#architectureporn',
      '#architexture',
      '#skyline',
      '#archidaily',
      '#perspective',
      '#composition',
      '#abstract',
      '#minimal',
      '#geometry',
      '#lights',
      '#downtown',
      '#geometric',
      '#lookingup',
      '#arts',
      '#citylights',
      '#pattern',
      '#cityview',
      '#cityphotography',
      '#instacity',
      '#citybestpics',
      '#citynights',
      '#nightlights',
      '#travelstyle',
      '#citycenter',
      '#nightshot',
      '#architectures',
    ],
  },
  {
    id: 'outdoors',
    label: 'Outdoors',
    tags: [
      '#outdoors',
      '#hiking',
      '#camping',
      '#hike',
      '#fishing',
      '#outside',
      '#wilderness',
      '#outdoor',
      '#getoutside',
      '#hunting',
      '#outdoorlife',
      '#forest',
      '#mountain',
      '#lake',
      '#wildlife',
      '#trees',
      '#optoutside',
      '#camp',
      '#backpacking',
      '#instanature',
      '#mothernature',
      '#trail',
      '#rei1440project',
      '#climbing',
      '#scenery',
      '#naturelover',
      '#adventures',
      '#outdoors',
      '#roadtrip',
      '#exploremore',
      '#traveler',
      '#travelling',
      '#ocean',
      '#traveltheworld',
      '#wander',
      '#discover',
      '#adventuretime',
      '#pnw',
      '#traveller',
      '#backpacker',
      '#landscape_lovers',
      '#landscapephotography',
      '#landscapelovers',
      '#landscape_captures',
      '#landscapes',
      '#sunrise',
      '#skyporn',
      '#cloudporn',
      '#tree',
      '#water',
      '#panorama',
      '#autumn',
      '#skylovers',
    ],
  },
  {
    id: 'photography',
    label: 'Photography',
    tags: [
      '#photos',
      '#picture',
      '#photograph',
      '#pic',
      '#fotografia',
      '#instaphoto',
      '#camera',
      '#photographylovers',
      '#photographyeveryday',
      '#instaphotography',
      '#photoart',
      '#ig_shutterbugs',
      '#photographyislife',
      '#photographysouls',
      '#capture',
      '#mobilephotography',
      '#photographers',
      '#exposure',
      '#photos',
      '#pictures',
      '#photographie',
      '#fashionphotography',
      '#snapshot',
      '#photographyislifee',
      '#composition',
      '#all_shots',
      '#moment',
      '#weddingphotography',
      '#outdoorphotography',
      '#photographs',
      '#focus',
      '#photographyoftheday',
      '#photographylover',
      '#photographylife',
      '#killeverygram',
      '#mkexplore',
      '#createexploretakeover',
      '#visualsgang',
      '#aov',
      '#uncalculated',
      '#urbanandstreet',
      '#yngkillers',
      '#instagoodmyphoto',
      '#hsdailyfeature',
      '#citykillerz',
      '#letsgosomewhere',
      '#meistershots',
      '#streetmobs',
      '#visualarchitects',
      '#hbouthere',
      '#featuremeinstagood',
      '#socality',
      '#makeportraits',
      '#postthepeople',
      '#rsa_streetview',
      '#createyourhype',
      '#folkgood',
      '#peoplescreative',
      '#exploremore',
      '#freedomthinkers',
      '#chasinglight',
      '#ig_color',
      '#theoutbound',
      '#primeshots',
    ],
  },
  {
    id: 'puppies',
    label: 'Puppies',
    tags: [
      '#pets',
      '#petsagram',
      '#animals',
      '#petstagram',
      '#animal',
      '#adorable',
      '#lovely',
      '#handsome',
      '#petsofinstagram',
      '#nice',
      '#cutie',
      '#fluffy',
      '#puppies',
      '#puppiesofinstagram',
      '#puppylove',
      '#instapuppy',
      '#pup',
      '#dogoftheday',
      '#doglover',
      '#puppyoftheday',
      '#puppygram',
      '#ilovemydog',
      '#dogsofig',
      '#pups',
      '#cutepuppy',
      '#doggy',
      '#lovedogs',
      '#dogs_of_instagram',
      '#instagramdogs',
      '#doglovers',
      '#puppylife',
      '#bestwoof',
      '#instadogs',
      '#pupsofinstagram',
      '#instapet',
      '#doglife',
      '#weeklyfluff',
      '#bulldog',
      '#dogsofinstaworld',
      '#dog_features',
      '#cutepetclub',
      '#paws',
    ],
  },
  {
    id: 'quotes',
    label: 'Quotes',
    tags: [
      '#quotes',
      '#quote',
      '#quoteoftheday',
      '#quotestoliveby',
      '#instaquote',
      '#words',
      '#poetry',
      '#lovequotes',
      '#lifequotes',
      '#quotestagram',
      '#inspirationalquotes',
      '#motivationalquotes',
      '#qotd',
      '#writer',
      '#wisdom',
      '#success',
      '#poem',
      '#wordporn',
      '#writersofinstagram',
      '#poetsofinstagram',
      '#sad',
      '#instaquotes',
      '#poems',
      '#truth',
      '#poetrycommunity',
      '#poet',
      '#frases',
      '#sayings',
      '#writing',
      '#sadquotes',
      '#successquotes',
      '#inspirational',
      '#inspirationalquote',
      '#quotesdaily',
      '#inspiringquotes',
      '#entrepreneurquotes',
      '#dailyquotes',
      '#quotes',
      '#positivequotes',
      '#onlinebusiness',
      '#motivational',
      '#wordsofwisdom',
      '#relationshipquotes',
      '#motivationalquote',
      '#successful',
      '#quotestags',
    ],
  },
  {
    id: 'selfies',
    label: 'Selfies',
    tags: [
      '#selfies',
      '#selfienation',
      '#selfietime',
      '#instaselfie',
      '#face',
      '#shamelessselefie',
      '#selfiee',
      '#selfieoftheday',
      '#selfiesfordays',
      '#hotgirl',
      '#selfiequeen',
      '#selfiesunday',
      '#selfiesaturday',
      '#followher',
      '#bored',
      '#girlfriend',
      '#eyes',
      '#selfiestick',
      '#handsome',
      '#selfshot',
      '#ff',
      '#myself',
      '#selfportrait',
      '#instame',
      '#shamelessselfie',
      '#selfiecentral',
      '#selfiepics',
      '#selfiemonday',
      '#selfieee',
      '#faceoftheday',
      '#selfiegram',
      '#selfieking',
      '#Slefiesunday',
      '#Selfiesi',
      '#instahub',
      '#selfiegame',
      '#gorgeous',
      '#perfect',
      '#color',
      '#lovely',
      '#nice',
      '#picture',
      '#stylish',
      '#confidence',
      '#selfworth',
      '#selflove',
      '#loveyourself',
      '#selfesteem',
      '#selfconfidence',
      '#empowerment',
      '#courage',
      '#confident',
      '#mouth',
      '#moi',
      '#instalovers',
      '#closeup',
      '#patinyabkk',
      '#tagsta_people',
      '#pose',
      '#asv',
      '#portraiture',
      '#feelgoodphoto',
      '#asava',
      '#portraits',
      '#instafamous',
      '#lovebird',
    ],
  },
  {
    id: 'skincare',
    label: 'Skincare',
    tags: [
      '#skincare',
      '#skin',
      '#antiaging',
      '#eyecream',
      '#mask',
      '#naturalskincare',
      '#healthyskin',
      '#beautycare',
      '#skincareaddict',
      '#skincarejunkie',
      '#skincareroutine',
      '#skincarediary',
      '#skincareblogger',
      '#instaskincare',
      '#skincareobsessed',
      '#skincareproducts',
      '#skincarelover',
      '#skincareregime',
      '#iloveskincare',
      '#skincareproduct',
      '#skincareluxury',
      '#koreanskincare',
      '#kbeauty',
      '#beautycommunity',
      '#beautyroutine',
      '#rasianbeauty',
      '#koreanbeauty',
      '#greenbeauty',
      '#skincare',
      '#beautyaddict',
      '#beautyjunkie',
      '#beautybloggers',
      '#bblogger',
      '#bbloggers',
      '#beautylover',
      '#beautygram',
      '#instabeautyau',
      '#makeupmess',
      '#igbeauty',
      '#makeupcommunity',
      '#makeupcollection',
      '#beautyblog',
      '#bblog',
      '#fashionbloggers',
      '#beautytips',
      '#beautyblogger',
      '#beautytrends',
      '#BeautyThings',
      '#youtubeguru',
      '#beautyproducts',
      '#beautyguru',
      '#girlystuff',
    ],
  },
  {
    id: 'startups',
    label: 'Startups',
    tags: [
      '#startups',
      '#startuplife',
      '#startup',
      '#entrepreneurs',
      '#entrepreneurship',
      '#businessowner',
      '#businesstips',
      '#entrepreneurlife',
      '#onlinebusiness',
      '#startupgrind',
      '#successmindset',
      '#businesslife',
      '#inspiringquotes',
      '#successquote',
      '#entrepreneurquotes',
      '#ceo',
      '#motivational',
      '#leadership',
      '#businessman',
      '#siliconvalley',
      '#hustle',
      '#startups',
      '#successquotes',
      '#businesswoman',
      '#inspiredaily',
      '#entrepreneurlifestyle',
      '#businessowners',
      '#ambition',
      '#moneymaker',
      '#businesspassion',
      '#founder',
      '#grind',
      '#learn',
      '#entrepreneur',
      '#start',
      '#business',
      '#success',
      '#successful',
      '#money',
      '#wealth',
      '#billionaire',
      '#quoteoftheday',
      '#hardwork',
      '#mindset',
      '#marketing',
      '#boss',
      '#inspire',
      '#quote',
      '#dreams',
    ],
  },
  {
    id: 'sunsets',
    label: 'Sunsets',
    tags: [
      '#skyporn',
      '#sunrise',
      '#sunsetlovers',
      '#cloudporn',
      '#sunsets',
      '#sunsetporn',
      '#sunset_madness',
      '#skylovers',
      '#sunset_pics',
      '#ocean',
      '#skyline',
      '#evening',
      '#dusk',
      '#horizon',
      '#sunset_hub',
      '#light',
      '#sunshine',
      '#orange',
      '#colors',
      '#tramonto',
      '#silhouette',
      '#water',
      '#lake',
      '#all_sunsets',
      '#sunsetsniper',
      '#outdoors',
      '#hiking',
      '#camping',
      '#hike',
      '#fishing',
      '#outside',
      '#wilderness',
      '#outdoor',
      '#getoutside',
      '#hunting',
      '#outdoorlife',
      '#forest',
      '#mountain',
      '#wildlife',
      '#trees',
      '#optoutside',
      '#camp',
      '#backpacking',
      '#instanature',
      '#mothernature',
      '#trail',
      '#rei1440project',
      '#climbing',
      '#scenery',
      '#naturelover',
      '#adventures',
      '#sunsethunter',
      '#ig_sunsetshots',
      '#skyporn',
      '#sunset_lovee',
      '#instasunsets',
      '#sunrays',
      '#scenicsunset',
      '#sunset_ig',
      '#irox_skyline',
      '#twilightscapes',
      '#isea_sunsets',
      '#sunset_stream',
      '#sunsetlover',
      '#sunset_captures',
      '#ig_sunset',
      '#instasunset',
      '#sunset_united',
      '#sunrise_and_sunsets',
      '#sol',
    ],
  },
  {
    id: 'travel',
    label: 'Travel',
    tags: [
      '#travelling',
      '#traveler',
      '#tourism',
      '#travelingram',
      '#igtravel',
      '#europe',
      '#traveller',
      '#travelblog',
      '#tourist',
      '#travelblogger',
      '#traveltheworld',
      '#roadtrip',
      '#instatraveling',
      '#instapassport',
      '#instago',
      '#outdoors',
      '#ocean',
      '#mytravelgram',
      '#traveladdict',
      '#world',
      '#hiking',
      '#lonelyplanet',
      '#travelpics',
      '#travelbug',
      '#travelphoto',
      '#travellife',
      '#globetrotter',
      '#ilovetravel',
      '#travelling',
      '#traveldiaries',
      '#travels',
      '#passportready',
      '#traveldeeper',
      '#getaway',
      '#instatravelling',
      '#instavacation',
      '#travelwriter',
      '#wanderer',
      '#getoutside',
      '#exploremore',
      '#outdoor',
      '#wander',
      '#discover',
      '#adventuretime',
    ],
  },
];
