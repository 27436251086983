import React from 'react';
import { Checkbox } from 'reakit/Checkbox';
import cx from 'clsx';

type SwitchProps = {
  label?: string;
  checked: boolean;
  wrapperClass?: string;
  onChange?(value: boolean): void;
};

export const Switch = ({ label = 'On', checked, wrapperClass, onChange, ...rest }: SwitchProps) => {
  return (
    <label className={cx('switchWrapper', wrapperClass)}>
      <span className="switchLabel">{label}</span>
      <div className={cx('switchRail', checked ? 'switchOn' : 'switchOff')}>
        <Checkbox
          className="switchInput"
          checked={checked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(e.target.checked)}
          {...rest}
        />
      </div>
    </label>
  );
};
