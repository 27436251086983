import { useRecoilValue } from 'recoil';

import { themeState } from './app-state';
import './styles/main.css';
import { Shuffler } from './components/shuffler';
import { Header, Banner, Footer } from './components/layout';
// import { Feedback } from './components/feedback';

export const App = () => {
  const theme = useRecoilValue(themeState);

  return (
    <div className="App" data-testid="app" data-theme={theme}>
      <header>
        <Header />
        <Banner />
      </header>

      <main>
        <div className="container">
          <Shuffler />
        </div>

        {/* <Feedback /> */}
      </main>

      <Footer />
    </div>
  );
};
