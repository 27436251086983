import React from 'react';
import { useSetRecoilState } from 'recoil';

import { tagsState, tagsHelperState } from './shuffler-state';
import { useMediaQuery } from './useMediaQuery';
import { Select } from '../common';
import { suggestedTagsData } from './suggested-tags-data';

export const ShufflerSuggestedTags = () => {
  const isTabletOrMobile = useMediaQuery();
  const setTags = useSetRecoilState(tagsState);
  const showTagsHelper = useSetRecoilState(tagsHelperState);
  const [isFirstTagClick, setIsFirstTagClick] = React.useState(true);

  const onTagClick = (value: string) => {
    const tags = suggestedTagsData.find((tag) => tag.id === value)?.tags;

    if (isFirstTagClick === true) {
      showTagsHelper(true);
      setIsFirstTagClick(false);
    }

    if (tags) {
      setTags(tags);
    }

    return;
  };

  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <React.Fragment>
          <label htmlFor="topic" className="sr-only">
            Select your topic
          </label>
          <Select
            id="topic"
            defaultLabel="Select your topic"
            defaultValue="default"
            options={suggestedTagsData}
            handleChange={(event) => onTagClick(event)}
          />
        </React.Fragment>
      ) : (
        <div className="suggestions">
          {suggestedTagsData.map((tag) => (
            <button className="btn btn--suggestion" data-tags={tag.id} key={tag.id} onClick={() => onTagClick(tag.id)}>
              {tag.label}
            </button>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};
