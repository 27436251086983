import React from 'react';
import { useCookies } from 'react-cookie';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { shuffle, uniq } from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { scroller } from 'react-scroll';

import { tagsState, quantityState, charCountState, tagCountState, formSubmittedState } from './shuffler-state';
import { parseCustomTagData, filterEmptyTags } from './shuffler-utils';
import { ShufflerSuggestedTags } from './ShufflerSuggestedTags';
import { ShufflerCustomTags } from './ShufflerCustomTags';
import { ShufflerCustomQuantity } from './ShufflerCustomQuantity';
import { ShufflerHelp } from './ShufflerHelp';

// @TODO:
// [ ] Set up React tests
// [ ] Can any functions be moved outside the component?

export const Shuffler = () => {
  const [tags, setTags] = useRecoilState(tagsState);
  const quantity = useRecoilValue(quantityState);
  const charCount = useRecoilValue(charCountState);
  const tagCount = useRecoilValue(tagCountState);
  const setIsFormSubmitted = useSetRecoilState(formSubmittedState);

  const [{ myCustomHashtags }, , removeCookie] = useCookies(['cookies']);

  const [shuffledTags, setShuffledTags] = React.useState('');
  const [copied, setCopied] = React.useState(false);
  const [placeholder, setPlaceholder] = React.useState(
    'To get your custom shuffled hashtags, add some hashtags in the text area above and click the “shuffle” button.'
  );

  // Update legacy hashtag data
  React.useEffect(() => {
    if (myCustomHashtags && typeof myCustomHashtags === 'string' && !filterEmptyTags(tags).length) {
      const preppedLegacyTags = parseCustomTagData(myCustomHashtags.replaceAll('.', ' '));
      setTags(preppedLegacyTags);
      removeCookie('myCustomHashtags');
    }
  }, [myCustomHashtags, tags, setTags, removeCookie]);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    // Update form state
    setShuffledTags('');
    setIsFormSubmitted(true);
    setCopied(false);

    // Validation messages
    if (quantity === 0 && tagCount === 0) {
      return setPlaceholder("Whoops! Looks like you're missing a few things.");
    }

    if (quantity === 0) {
      return setPlaceholder('Hashtag quantity must be greater than zero.');
    }

    if (tagCount === 0) {
      return setPlaceholder("Whoops! Looks like you haven't added any hashtags yet.");
    }

    // Filter out empty tags and get unique values only
    const scrubbedTags = uniq(filterEmptyTags(tags));

    // Shuffle them
    const results = shuffle(scrubbedTags).slice(0, quantity).join(' ');
    setShuffledTags(results);

    // Scroll to results
    scroller.scrollTo('shuffleButton', {
      duration: 1000,
      offset: -25,
      smooth: 'easeInOutQuart',
    });

    return;
  };

  return (
    <div>
      <section className="section">
        <h2 className="section-heading">You can choose a topic to get started:</h2>
        <ShufflerSuggestedTags />
      </section>

      <section className="section">
        <h2 className="section-heading">Or add your own custom hashtags:</h2>
        <form>
          <ShufflerCustomTags />

          <div className="custom-tags-footer">
            <div className="helper">
              <div className="helper-item">
                <strong>Total hashtags: </strong>
                {tagCount}
              </div>
              <div className="helper-item">
                <strong>Total characters: </strong>
                {charCount}/4000
              </div>
            </div>
            <ShufflerHelp />
          </div>

          <div className="form-footer">
            <ShufflerCustomQuantity />
            <button id="shuffleButton" className="btn btn--submit" type="submit" onClick={handleSubmit}>
              Do the shuffle
            </button>
          </div>
        </form>
      </section>

      <section className="section" id="results">
        <h2 className="section-heading">Your shuffled hashtags will appear here:</h2>

        <div className="results-wrapper">
          <textarea className="results" readOnly={true} placeholder={placeholder} value={shuffledTags}></textarea>

          {shuffledTags.length > 0 && (
            <CopyToClipboard text={shuffledTags} onCopy={() => setCopied(true)}>
              <button className="helper-box helper copy-results" onBlur={() => setCopied(false)}>
                {copied === false ? 'Copy to clipboard' : 'Copied!'}
              </button>
            </CopyToClipboard>
          )}
        </div>

        <div className="helper">Want a fresh set of hashtags? Just hit the "shuffle" button again.</div>
      </section>
    </div>
  );
};
