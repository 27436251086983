import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { times } from 'lodash';
import cx from 'clsx';

import { quantityState, formSubmittedState } from './shuffler-state';
import { useMediaQuery } from './useMediaQuery';
import { Select } from '../common';

export const ShufflerCustomQuantity = () => {
  const isTabletOrMobile = useMediaQuery();
  const [quantity, setQuantity] = useRecoilState(quantityState);
  const isFormSubmitted = useRecoilValue(formSubmittedState);

  const handleChange = (value) => {
    if (value < 1) {
      return setQuantity(1);
    }

    if (value > 30) {
      return setQuantity(30);
    }

    return setQuantity(Number(value));
  };

  return (
    <div className="tag-count">
      <div>
        <label className="tag-count-label" htmlFor="quantity">
          No. of hashtags:
        </label>
        <div className="helper md-up">30 maximum</div>
      </div>

      {isTabletOrMobile ? (
        <Select
          id="quantity"
          defaultLabel="Select your quantity"
          defaultValue={quantity}
          options={times(30).map((number) => ({
            id: number + 1,
            label: number + 1,
          }))}
          handleChange={(event) => setQuantity(Number(event))}
        />
      ) : (
        <input
          data-testid="quantity-input"
          className={cx('tag-count-input', isFormSubmitted === true && quantity === 0 && 'error')}
          id="quantity"
          name="quantity"
          type="number"
          min="1"
          max="30"
          value={quantity}
          onChange={(event) => handleChange(event.target.value)}
        />
      )}
    </div>
  );
};
