const renderedTitle = 'Hashtag Shuffle'.split('').map((letter, index) => (
  <span className={`letter letter-${index}`} key={index}>
    {letter}
  </span>
));

export const Banner = () => {
  return (
    <div className="banner">
      <div className="container">
        <h1 className="banner-title">
          <span className="banner-title--small">Do the </span>
          <span className="banner-title--large">{renderedTitle}</span>
        </h1>

        <div className="banner-description">
          <p>
            Have a lot of hashtags? Don't know which to pick? Use this tool to randomize your own custom hashtags. Add
            your hashtags, and the tool will pull 30 (or less, if you like) at random.
          </p>
          <p>
            Bookmark this page and use it as often as you need&mdash;this site uses your local browser storage to
            remember your hashtags for your next visit.
          </p>
        </div>
      </div>
    </div>
  );
};
