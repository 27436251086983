import { FaAngleDown } from 'react-icons/fa';

type SelectOption = {
  id: string | number;
  label: string | number;
};

type SelectProps = {
  id: string;
  defaultLabel: string;
  defaultValue: string | number;
  options: Array<SelectOption>;
  handleChange: any;
};

export const Select = ({ id, defaultLabel, defaultValue, options, handleChange }: SelectProps) => {
  return (
    <div className="options-dropdown-wrapper">
      <select
        id={id}
        name={id}
        className="options-dropdown"
        defaultValue={defaultValue}
        onChange={(event) => handleChange(event.target.value)}
        style={{ width: '100%' }}
      >
        <option value="default" disabled>
          {defaultLabel}
        </option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>

      <FaAngleDown className="options-dropdown-arrow" />
    </div>
  );
};
