import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const tagsState = atom({
  key: 'tagsState',
  default: [] as string[],
  effects_UNSTABLE: [persistAtom],
});

export const charCountState = selector({
  key: 'charCountState',
  get: ({ get }) => {
    const text = get(tagsState).join(' ');
    return text.length;
  },
});

export const tagCountState = selector({
  key: 'tagCountState',
  get: ({ get }) => {
    const tags = get(tagsState);
    return tags.filter((tag) => tag.length > 0).length;
  },
});

export const quantityState = atom({
  key: 'quantityState',
  default: 30 as number,
  effects_UNSTABLE: [persistAtom],
});

export const tagsHelperState = atom({
  key: 'tagsHelperState',
  default: false as boolean,
});

export const formSubmittedState = atom({
  key: 'formSubmittedState',
  default: false as boolean,
});
