export const Footer = () => {
  return (
    <footer className="footer">
      <div className="container container--footer">
        <div className="footer-about">
          <h2 className="section-heading">Who made this?</h2>
          <p>
            Hi, I’m Julia Evanczuk, a web developer based in New York City. I built this tool because pretty much all I
            share on Instagram are{' '}
            <a href="https://www.instagram.com/museumofmostlypudding/" target="_blank" rel="noreferrer">
              pictures of weird old food
            </a>{' '}
            and I needed a way to keep my hashtags fresh.
          </p>
          <p>
            Have a suggestion for a feature? Found a bug? <a href="mailto:bugreport@hashtagshuffle.com">Email me!</a>
          </p>
        </div>

        <div className="footer-follow">
          <h3>
            Find this site useful? Awesome. <br />
            Here's a few ways to say thanks:
          </h3>
          <a className="btn btn--footer kofi" href="https://ko-fi.com/juliaevk" target="_blank" rel="noreferrer">
            <img alt="" src="/img/kofi.png" height="19" width="30" /> Buy me a coffee!
          </a>
          <a
            className="btn btn--footer"
            href="https://www.instagram.com/museumofmostlypudding/"
            target="_blank"
            rel="noreferrer"
          >
            Follow me on Instagram: @museumofmostlypudding
          </a>
        </div>
      </div>
    </footer>
  );
};
