import { FaQuestionCircle } from 'react-icons/fa';

import { Modal } from '../common';

export const ShufflerHelp = () => {
  return (
    <Modal
      label="Help"
      disclosure={
        <button className="help-trigger">
          <span className="help-trigger-text">Help</span>
          <FaQuestionCircle className="help-trigger-icon" />
        </button>
      }
    >
      <>
        <h2 className="modal-heading">Rules of the tool</h2>
        <ol className="modal-list">
          <li>
            Letters, numbers, underscores, and emojis only. The tool validates your hashtags as you type and adds pound
            signs (#) automatically.
          </li>
          <li>Your list of custom hashtags is limited to 4,000 characters.</li>
          <li>Your data is saved as you type, so feel free to safely refresh or come back later.</li>
          <li>That's it, happy shuffling!</li>
        </ol>
      </>
    </Modal>
  );
};
