export const parseCustomTagData = (value) => {
  return value
    .replace(/[\s\t]{1,}/g, ' ') // replace large whitespace with single space
    .replace(/[&\\/#,+()$~^%.`'":;*?!<>={}|[\]—–@-]/g, '') // remove forbidden characters
    .replace(/#{2,}/g, '#') // replace any double hashes
    .trimStart()
    .split(' ')
    .map((tag) => (tag.charAt(0) === '#' || tag.length === 0 ? tag : `#${tag}`));
};

export const filterEmptyTags = (tags: string[]) => {
  return tags.filter((tag) => tag.length > 0);
};
