import { useRecoilState } from 'recoil';

import { themeState } from '../../app-state';
import { Switch } from '../common';

export const Header = () => {
  const [theme, setTheme] = useRecoilState(themeState);
  const isLightTheme = theme === 'light';

  const handleSwitchChange = () => {
    if (isLightTheme) {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  return (
    <div className="header">
      <div className="headerContainer container">
        <div className="headerText tip">
          <div className="tip compact">
            <a className="btn kofi" href="https://ko-fi.com/juliaevk" target="_blank" rel="noreferrer">
              <img alt="" src="/img/kofi.png" height="13" width="20" /> Buy me a coffee!
            </a>
          </div>
          {/* <div className="tip md-up">
            Like this site?{' '}
            <a href="https://ko-fi.com/juliaevk" target="_blank" rel="noreferrer">
              Buy me a coffee!
            </a>
          </div> */}
        </div>
        <Switch
          label="Dark mode"
          wrapperClass="themeSwitch headerText"
          checked={!isLightTheme}
          onChange={handleSwitchChange}
        />
      </div>
    </div>
  );
};
