import React from 'react';
import { useRecoilValue } from 'recoil';
import { useDialogState, Dialog, DialogDisclosure, DialogBackdrop } from 'reakit/Dialog';
import { FaTimes } from 'react-icons/fa';

import { themeState } from '../../app-state';

export const Modal = ({ disclosure, label, children, ...props }) => {
  const dialog = useDialogState({ animated: true });
  const theme = useRecoilValue(themeState);

  return (
    <>
      <DialogDisclosure {...dialog} ref={disclosure.ref} {...disclosure.props}>
        {(disclosureProps) => React.cloneElement(disclosure, disclosureProps)}
      </DialogDisclosure>
      <DialogBackdrop className="modal-overlay" data-theme={theme} {...dialog}>
        <Dialog aria-label={label} className="modal" {...dialog} {...props}>
          {children}
          <div className="modal-footer">
            <button className="modal-close" onClick={dialog.hide}>
              Close
              <FaTimes className="modal-close-icon" />
            </button>
          </div>
        </Dialog>
      </DialogBackdrop>
    </>
  );
};
