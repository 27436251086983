import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import cx from 'clsx';

import { tagsState, tagsHelperState, tagCountState, formSubmittedState } from './shuffler-state';
import { parseCustomTagData } from './shuffler-utils';

export const ShufflerCustomTags = () => {
  const [tags, setTags] = useRecoilState(tagsState);
  const [showTagsHelper, setShowTagsHelper] = useRecoilState(tagsHelperState);
  const isFormSubmitted = useRecoilValue(formSubmittedState);
  const tagCount = useRecoilValue(tagCountState);

  return (
    <React.Fragment>
      <label htmlFor="tags" className="sr-only">
        Add your custom hashtags
      </label>
      <div className="custom-tags-holder">
        <textarea
          className={cx('custom-tags', isFormSubmitted === true && tagCount === 0 && 'error')}
          name="tags"
          id="tags"
          value={tags.join(' ')}
          onChange={(event) => setTags(parseCustomTagData(event.target.value))}
          onFocus={() => setShowTagsHelper(false)}
          spellCheck="false"
          placeholder="#add #your #hashtags #here"
          maxLength={4000}
        ></textarea>
        <div className={cx('helper-box helper', showTagsHelper && 'show')}>
          Nice! Now you can customize this list&mdash;delete the ones you don't need and add tags that are more relevant
          to your feed.
        </div>
      </div>
    </React.Fragment>
  );
};
